import "./footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div>Copyright © 2023 Gambini Lux.</div>
    </div>
  );
};

export default Footer;
