import "./quote.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const Quote = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Header type="quote" />
      <div className="container">
        <form
          // action="https://qeeixxnhqb.execute-api.us-east-2.amazonaws.com/quote/quote"
          method="POST"
          onSubmit={(event) => {
            Swal.fire({
              icon: "success",
              title: "Quote Sent Successfully",
            });

            var details = {
              firstname: document.getElementById("fname").value,
              lastname: document.getElementById("lname").value,
              phonenumber: document.getElementById("phonenumber").value,
              email: document.getElementById("email").value,
              subject: document.getElementById("subject").value,
            };
            var formBody = [];
            for (var property in details) {
              var encodedKey = encodeURIComponent(property);
              var encodedValue = encodeURIComponent(details[property]);
              formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
            fetch(
              "https://ybrzdxcud7.execute-api.us-east-2.amazonaws.com/gambinilux",
              {
                method: "POST",
                headers: {
                  "Content-Type":
                    "application/x-www-form-urlencoded;charset=UTF-8",
                },
                body: formBody,
              }
            );
            // event.preventDefault();
            navigate("/");
          }}
        >
          <div className="row">
            <div className="col-25">
              <label htmlFor="fname">First Name</label>
            </div>
            <div className="col-75">
              <input
                type="text"
                id="fname"
                name="firstname"
                placeholder="Your first name.."
              />
            </div>
          </div>
          <div className="row">
            <div className="col-25">
              <label htmlFor="lname">Last Name</label>
            </div>
            <div className="col-75">
              <input
                type="text"
                id="lname"
                name="lastname"
                placeholder="Your last name.."
              />
            </div>
          </div>
          <div className="row">
            <div className="col-25">
              <label htmlFor="phonenumber">Phone number</label>
            </div>
            <div className="col-75">
              <input
                type="text"
                id="phonenumber"
                name="phonenumber"
                placeholder="Your phone number.."
              />
            </div>
          </div>
          <div className="row">
            <div className="col-25">
              <label htmlFor="email">Email</label>
            </div>
            <div className="col-75">
              <input
                type="text"
                id="email"
                name="email"
                placeholder="Your email address.."
              />
            </div>
          </div>
          <div className="row">
            <div className="col-25">
              <label htmlFor="subject">Subject</label>
            </div>
            <div className="col-75">
              <textarea
                name="subject"
                id="subject"
                cols="90"
                rows="10"
                placeholder="To send quote for your trip. Please include your desired pickup location and time etc. Thank you!"
              ></textarea>
            </div>
          </div>
          <div className="row center">
            <input type="submit" value="Submit" />
          </div>
        </form>
        <div className="listContainer">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Quote;
