import "./contact.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import {
  faPhoneSquareAlt,
  faMailBulk,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Contact = () => {
  return (
    <div>
      <Header type="contact" />
      <div className="container">
        <div className="description">
          <p>
            We are better than Uber! Great prices! Great cars! Great customer
            sastisfaction!
          </p>
        </div>

        <div className="contacts">
          <ul>
            <li>
              <FontAwesomeIcon icon={faPhoneSquareAlt} />
            </li>
            <li>
              <a href="tel:4255898886">+1 (425) 589-8886</a>
            </li>
            <li>
              <FontAwesomeIcon icon={faMailBulk} />
            </li>
            <li>
              <a href="mailto:gambinilux@gmail.com">gambinilux@gmail.com</a>
            </li>
          </ul>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Contact;
