import "./intro.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleArrowLeft,
  faCircleArrowRight,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const Intro = () => {
  const [slideNumber, setSlideNumber] = useState(0);
  const [open, setOpen] = useState(false);

  const photos = [
    {
      src: require("./assets/1.jpg"),
    },
    {
      src: require("./assets/2.jpg"),
    },
    {
      src: require("./assets/3.jpg"),
    },
    {
      src: require("./assets/4.jpg"),
    },
    {
      src: require("./assets/5.jpg"),
    },
    {
      src: require("./assets/6.jpg"),
    },
    {
      src: require("./assets/7.jpg"),
    },
    {
      src: require("./assets/8.jpg"),
    },
    {
      src: require("./assets/9.jpg"),
    },
  ];

  const handleOpen = (i) => {
    setSlideNumber(i);
    setOpen(true);
  };

  const handleMove = (direction) => {
    let newSlideNumber;

    if (direction === "l") {
      newSlideNumber = slideNumber === 0 ? 5 : slideNumber - 1;
    } else {
      newSlideNumber = slideNumber === 5 ? 0 : slideNumber + 1;
    }

    setSlideNumber(newSlideNumber);
  };

  return (
    <div>
      <Header type="intro" />
      <div className="container">
        {open && (
          <div className="slider">
            <FontAwesomeIcon
              icon={faCircleXmark}
              className="close"
              onClick={() => setOpen(false)}
            />
            <FontAwesomeIcon
              icon={faCircleArrowLeft}
              className="arrow"
              onClick={() => handleMove("l")}
            />
            <div className="sliderWrapper">
              <img src={photos[slideNumber].src} alt="" className="sliderImg" />
            </div>
            <FontAwesomeIcon
              icon={faCircleArrowRight}
              className="arrow"
              onClick={() => handleMove("r")}
            />
          </div>
        )}
        <div className="wrapper">
          <div className="images">
            {photos.map((photo, i) => (
              <div className="imgWrapper" key={i}>
                <img
                  onClick={() => handleOpen(i)}
                  src={photo.src}
                  alt=""
                  className="img"
                />
              </div>
            ))}
          </div>
          <div className="details">
            <div className="detailsTexts">
              <p className="desc">
                Gambini Lux, established in 2023, specializes in providing
                first-class limousine and luxury transportation services to
                clients in Seattle. Our commitment to excellence and attention
                to detail has earned us a solid reputation as one of the premier
                providers of limousine and luxury transportation in the Seattle
                area. Whether you're looking for corporate travel, wedding
                transportation, or special occasion transportation, we have the
                experience and expertise to exceed your expectations and make
                your event unforgettable. So if you're looking for a reliable
                and luxury transportation provider, look no further than Gambini
                Lux. At Gambini Lux, we offer a comprehensive selection of
                transportation services to suit a variety of needs. Our services
                include corporate travel, weddings, special occasions, and much
                more. Whether you're looking for a sleek and stylish limousine
                for a corporate event or a spacious party vehicle for a
                fun-filled night out, we have the perfect vehicle to make your
                event extra special. Our team of experienced professionals is
                dedicated to providing exceptional service and attention to
                detail to ensure that your experience with us is nothing short
                of extraordinary. We understand the importance of making a
                lasting impression and we're here to help you do just that. So
                if you're looking for a luxurious and memorable transportation
                experience, look no further than Gambini Lux
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Intro;
